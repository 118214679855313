import React, {Component} from 'react';
import Axios from "axios";
import AppUrl from "../AppUrl/ApiUrl";
import Menu from "../components/Menu";
import Loading from "../components/Loading";
import NotImg from "../images/404.png";
import {Link} from "react-router-dom";
import Payments from "../components/user/Payments";
import Wallets from "../components/user/Wallets";
import BalanceAdjustUser from "../components/user/BalanceAdjustUser";
import NftUser from "../components/user/NftUser";

class UserDetails extends Component {
    constructor({match}) {
        super();
        this.state={
            token:"",name:"",username:"",email:"",
            id:match.params.id,profile_status:"",usertype:"",
            statusselect:[{name:"Active",val:"1"},{name:"Inactive",val:"0"}],
            profileselect:[{name:"Verified",val:"1"},{name:"Unverified",val:"0"}],
            role:[{name:"Admin"},{name:"Manager"},{name:"User"}],user_id:"",
            loading:true,path:"payments",lip:"",count:"0",verify:""
        }
    }
    componentDidMount() {
        var token = localStorage.getItem("admintoken")
        this.setState({token:token})
        const form = new FormData()
        form.append("token",token)
        form.append("id",this.state.id)
        Axios.post(AppUrl.baseurl+"oneuser",form)
            .then(res=>{
                //console.log(res.data)
                if(res.data.error){
                    var a=0
                }else{
                    var val = res.data[0]
                    this.setState(
                        {name:val["name"],username:val["username"],email:val["email"],
                            user_id:val["user_id"],lip:val["lip"],
                            profile_status:val["profile_status"],verify:val["verify"],
                            usertype:val["usertype"],loading:false
                        }
                    )
                    setTimeout(()=>{
                        this.getRefCount()
                    },100);
                }

            })
            .catch(err=>{
                console.log(err)
            })

    }

    getRefCount=()=>{
        const form = new FormData()
        form.append("token",this.state.token)
        form.append("user_id",this.state.user_id)
        Axios.post(AppUrl.baseurl+"refcount-user",form)
            .then(res=>{
                console.log(res.data.count)
                if(res.data.count){
                    console.log(res.data.count)
                    this.setState({count:res.data.count})
                }

            })
            .catch(err=>{
                console.log(err)
            })
    }

    onReportChange=(name)=>{
        this.setState({path:name})
    }

    render() {

        const st = this.state.statusselect.map(res=>{
            return(<>
                    {this.state.status==res.val?
                        <option selected value={res.val}>{res.name}</option>:
                        <option value={res.val}>{res.name}</option>
                    }
                </>
            )
        })

        const pt = this.state.profileselect.map(res=>{
            return(<>
                    {this.state.profile_status==res.val?
                        <option selected value={res.val}>{res.name}</option>:
                        <option value={res.val}>{res.name}</option>
                    }
                </>
            )
        })

        const utype = this.state.role.map(res=>{
            return(<>
                    {this.state.usertype==res.name?
                        <option selected value={res.name}>{res.name}</option>:
                        <option value={res.name}>{res.name}</option>
                    }
                </>
            )
        })

        return (
            <Menu title={this.state.name}>
                <h3 className="bg-white title-head">  {this.state.name} Details</h3>
                <div className="container-fluid currency-add">
                    <div className="row">

                        {this.state.loading==true?
                            <Loading/>:
                            <div className="col-md-12 ">
                               <div className="row">
                                   <div className="col-md-12">
                                       <div className="profile table-responsive">
                                           <table className="table ">
                                               <tr>
                                                   <td>
                                                       <p>
                                                           {
                                                               this.state.image?
                                                                   <img src={AppUrl.photoUrl+this.state.image} style={{height:"100px",width:"100px", borderRadius:"50%"}} />:
                                                                   <img src={NotImg} style={{height:"100px",width:"100px", borderRadius:"50%"}} />
                                                           }
                                                       </p>
                                                   </td>
                                                   <td>
                                                       <p><b>Ref Count</b></p>
                                                       <p>{this.state.count}</p>
                                                   </td>
                                                   <td>
                                                       <p><b>Ref Code</b></p>
                                                       <p>{this.state.user_id}</p>
                                                   </td>

                                                   <td>
                                                       <p><b>Email</b></p>
                                                       <p>{this.state.email}</p>
                                                   </td>

                                                   <td>
                                                       <p><b>UID</b></p>
                                                       <p>{this.state.id}</p>
                                                   </td>
                                                   <td>
                                                       <p><b>Role</b></p>
                                                       <p>{this.state.usertype}</p>
                                                   </td>
                                                   <td>
                                                       <p><b>IP Address</b></p>
                                                       <p>{this.state.lip}</p>
                                                   </td>
                                               </tr>
                                               <tr >
                                                   <td>
                                                       <p></p>
                                                   </td>

                                                   <td>
                                                       <p><b>Profile</b></p>
                                                       <p className={this.state.profile_status=="0"?"text-danger":this.state.profile_status=="1"?"text-success":"text-dark"}>
                                                           {this.state.profile_status=="1"?"Active":"Blocked"}</p>
                                                   </td>
                                                   <td>
                                                       <p><b>Verification</b></p>
                                                       <p className={this.state.verify=="Rejected"?"text-danger":this.state.verify=="Approved"?"text-success":"text-dark"}>
                                                           {this.state.verify=="No"?"N/A":this.state.verify}</p>
                                                   </td>
                                                   <td>
                                                       <Link className="btn btn-primary" style={{float:"left"}} to={"/user/edit/"+this.state.id}>Edit Profile</Link>
                                                   </td>
                                               </tr>
                                           </table>
                                       </div>
                                   </div>
                               </div>
                            </div>
                        }
                        <div className="report ">
                            <ul className="report-head">
                                <li onClick={this.onReportChange.bind(this,"payments")}><Link to={"/user/details/"+this.state.id} className={this.state.path=="payments"?"active":""}>Payments</Link></li>
                                <li onClick={this.onReportChange.bind(this,"wallet")}><Link to={"/user/details/"+this.state.id} className={this.state.path=="wallet"?"active":""}>Wallet</Link></li>
                                <li onClick={this.onReportChange.bind(this,"balance")}><Link to={"/user/details/"+this.state.id} className={this.state.path=="balance"?"active":""}>Balance</Link></li>
                                <li onClick={this.onReportChange.bind(this,"nftuser")}><Link to={"/user/details/"+this.state.id} className={this.state.path=="nftuser"?"active":""}>NFT</Link></li>
                            </ul>
                            <div className="report-body bg-white">
                                {this.state.path=="payments"?<Payments uid={this.state.id} />:""}
                                {this.state.path=="wallet"?<Wallets user_id={this.state.user_id} uid={this.state.id} />:""}
                                {this.state.path=="balance"?<BalanceAdjustUser user_id={this.state.user_id} uid={this.state.id} email={this.state.email} />:""}
                                {this.state.path=="nftuser"?<NftUser user_id={this.state.user_id} uid={this.state.id} email={this.state.email} />:""}
                            </div>
                           <br/><br/>
                        </div>
                    </div>
                </div>
            </Menu>
        );
    }
}

export default UserDetails;
