import React, {Component} from 'react';
import moment from "moment";
import Axios from "axios";
import AppUrl from "../AppUrl/ApiUrl";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import Menu from "../components/Menu";
import Loading from "../components/Loading";

class NftHistory extends Component {
    constructor() {
        super();
        this.state={
            token:'',uid:"",count:10,fromDate:new Date(),toDate:new Date(),
            start:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            end:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            data:[],coin:[],coin_raw_id:"",loading:true,status:"",type:"",pcount:""
        }
    }
    async componentDidMount() {
        await this.myPayments()
    }

    status=(e)=>{
        this.setState({status:e.target.value})
    }
    type=(e)=>{
        this.setState({type:e.target.value})
    }
    coin_raw_id=(e)=>{
        this.setState({coin_raw_id:e.target.value})
    }

    myPayments=()=>{
        var token = localStorage.getItem("admintoken")
        if(token){
            var fda = new FormData()
            fda.append("token",token)
            fda.append("limit",this.state.count)
            Axios.post(AppUrl.baseurl+"nft/allhistory/"+this.state.count,fda)
                .then(res => {
                    if (res.data.error) {
                        this.myPayments()
                    } else {
                        this.setState({data: res.data, loading: false})
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.myPayments()
                })
        }
    }

    loadMore=async (e)=>{
        var countval = this.state.count+10
        await this.setState({count:countval})
        await this.myPayments()
    }

    render() {
        const datapending = this.state.data.map(res=>{
            return(
                //<Link to={"/user/details/"+res.uid}>{res.email}</Link>
                <>
                    <tr>
                        <td>{moment(res.created_at).format("MMM Do YY")}</td>
                        <td> {res.title}</td>
                        <td>{res.id} </td>
                        <td>{res.price} {res.coin_symbol} </td>
                        <td>{res.qtn} {res.title}  </td>
                        <td>{<Link to={"/user/details/"+res.buyer}>{res.buyer}</Link>} </td>
                        <td>{<Link to={"/user/details/"+res.seller}>{res.seller}</Link>}  </td>
                        <td className="text-success">{res.status}  </td>
                    </tr>
                </>
            )
        },)

        var currency = this.state.coin.map(res=>{
            return(
                <>
                    <option value={res.id}>{res.coin_symbol}</option>
                </>
            )

        })

        return (

            <Menu title="All NFT History ">
                <>
                    <h3 className="bg-white title-head"> NFT History</h3>

                    <div className="bg-white m-0 pt-2">
                        <div className="table-responsive report-table-main ">
                            <title>All NFT Order </title>
                            <table className="table table-striped report-table ">
                                <thead>
                                <tr>
                                    <th>Time (UTC)</th>
                                    <th>NFT </th>
                                    <th> ID  </th>
                                    <th>Price </th>
                                    <th>Qtn </th>
                                    <th>Buyer UID</th>
                                    <th>Seller UID</th>
                                    <th>Status</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.loading==true?<Loading/>:datapending}
                                </tbody>
                            </table>
                            {
                                this.state.loading==true?"":
                                    this.state.data.length>0?""
                                        :<p className="noorderdata">NFT History Data Not Available</p>
                            }

                        </div>
                        {
                            this.state.data.length>=10?
                                <div className="row" style={{margin:"0"}}>
                                    <div className="col-md-12 loadmore mb-5" >
                                        <button onClick={this.loadMore} className="btn btn-secondary btn-sm shadow-none"> Load more ...</button>
                                    </div>
                                </div>:""
                        }
                    </div>

                </>
            </Menu>
        );
    }
}

export default NftHistory;