class AppUrl {
	static baseurl = "https://api.kococoin.com/"
    //static baseurl = 'http://127.0.0.1:5000/'
    //static baseurl ='http://37.27.62.60:5000/'
    static photoUrl = this.baseurl+'static/images/';
    static photoUrlNft = this.baseurl+'static/nft/';


}

export default AppUrl