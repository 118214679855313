import React, {Component} from 'react';
import Axios from "axios";
import AppUrl from "../AppUrl/ApiUrl";
import {toast} from "react-toastify";
import Menu from "../components/Menu";
import ApiUrl from "../AppUrl/ApiUrl";
import moment from "moment";
import DatePicker from "react-datepicker";

class NftEdit extends Component {
    constructor({match}) {
        super();
        this.state={
            id:match.params.id,
            token:"",title:"",des:"",logo:"",logo2:"",cover:"",cover2:"",loading:true,
            nftby:"",price:"0",items:"0",volume:"0",sales:"0",coin_id:"",status:"",coin:[],trade:"",
            uptime:new Date(),end:moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
        }

    }
    componentDidMount() {
        var token = localStorage.getItem("admintoken")
        this.setState({token:token})
        Axios.get(AppUrl.baseurl+"nft/"+this.state.id)
            .then(res=>{
                var val = res.data[0]
                console.log(val["trade"])
                this.setState({
                    title:val["title"],des:val["des"],logo:val["logo"],cover:val["cover"],
                    loading:false,trade:val["trade"],end:val["uptime"],
                    nftby:val["nftby"],price:val["price"],items:val["items"],uptime:val["uptime"],
                    volume:val["volume"],sales:val["sales"],coin_id:val["coin_id"],status:val["status"],
                })
            })
            .catch(err=>{
                console.log(err)
            })
        Axios.get(AppUrl.baseurl+"all-coin")
            .then(res=>{
                console.log(res.data)
                this.setState({coin:res.data})
            })
            .catch(err=>{
                console.log(err)
            })
    }
    trade=(e)=>{
        this.setState({trade:e.target.value})
    }
    title=(e)=>{
        this.setState({title:e.target.value})
    }
    des=(e)=>{
        this.setState({des:e.target.value})
    }
    toDate=(date)=>{
        this.setState({uptime:date,end:moment(date).format("YYYY-MM-DD HH:mm:ss")})
    }
    logo=(e)=>{
        this.setState({logo:e.target.files[0]})
        var file = e.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function (e) {
            this.setState({
                logo2: [reader.result]
            })
        }.bind(this);
    }

    cover=(e)=>{
        this.setState({cover:e.target.files[0]})
        var file = e.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function (e) {
            this.setState({
                cover2: [reader.result]
            })
        }.bind(this);
    }

    nftby=(e)=>{
        this.setState({nftby:e.target.value})
    }
    price=(e)=>{
        this.setState({price:e.target.value})
    }
    items=(e)=>{
        this.setState({items:e.target.value})
    }
    volume=(e)=>{
        this.setState({volume:e.target.value})
    }
    sales=(e)=>{
        this.setState({sales:e.target.value})
    }
    coin_id=(e)=>{
        this.setState({coin_id:e.target.value})
    }
    status=(e)=>{
        this.setState({status:e.target.value})
    }

    onsubmitForm=async (event)=>{
        var formData = new FormData()
        formData.append("token",this.state.token)
        formData.append("title",this.state.title)
        formData.append("des",this.state.des)
        formData.append("logo",this.state.logo)
        formData.append("cover",this.state.cover)
        formData.append("nftby",this.state.nftby)
        formData.append("price",this.state.price)
        formData.append("items",this.state.items)
        formData.append("volume",this.state.volume)
        formData.append("sales",this.state.sales)
        formData.append("coin_id",this.state.coin_id)
        formData.append("status",this.state.status)
        formData.append("uptime",this.state.end)
        formData.append("trade",this.state.trade)
        formData.append("id",this.state.id)
        await Axios.post(AppUrl.baseurl+"nft/update",formData)
            .then(res=> {
                if(res.data.success){
                     this.successMsg(res.data.success)
                    setTimeout(
                        () => this.props.history.push(`/nft`),
                        1000
                    )
                }else{
                   this.errorMsg(res.data.error)
                }
            })
            .catch(err=>{
                console.log(err)
            })


        //event.preventDefault()
    }

    onsubmitFormLogo=async (event)=>{
        var formData = new FormData()
        formData.append("token",this.state.token)
        formData.append("logo",this.state.logo)
        formData.append("id",this.state.id)
        await Axios.post(AppUrl.baseurl+"nft/updatelogo",formData)
            .then(res=> {
                if(res.data.success){
                    this.successMsg(res.data.success)
                    setTimeout(
                        () => this.props.history.push(`/nft`),
                        1000
                    )
                }else{
                    this.errorMsg(res.data.error)
                }
            })
            .catch(err=>{
                console.log(err)
            })


        //event.preventDefault()
    }

    onsubmitFormCover=async (event)=>{
        var formData = new FormData()
        formData.append("token",this.state.token)
        formData.append("cover",this.state.cover)
        formData.append("id",this.state.id)
        await Axios.post(AppUrl.baseurl+"nft/updatecover",formData)
            .then(res=> {
                if(res.data.success){
                    this.successMsg(res.data.success)
                    setTimeout(
                        () => this.props.history.push(`/nft`),
                        1000
                    )
                }else{
                    this.errorMsg(res.data.error)
                }
            })
            .catch(err=>{
                console.log(err)
            })


        //event.preventDefault()
    }

    successMsg=(val)=>{
        toast.success(val, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    errorMsg=(val)=>{
        toast.error(val, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    render() {
        console.log("Trade : ",this.state.trade)
        var coin = this.state.coin.map(res=>{
            return(
                <>
                    <option selected={res.id == this.state.coin_id} value={res.id}>{res.coin_symbol}</option>
                </>
            )

        })

        return (
            <Menu title="Edit NFT">
                <h3 className="bg-white title-head"> Edit NFT  </h3>
                <div className="container-fluid currency-add">
                    <div className="row">
                        <div className="col-md-11">
                            <form className="mt-5" >
                                <div className="row">

                                    <div className="col-md-12">
                                        <label>Title</label>
                                        <input type="text" onChange={this.title} value={this.state.title} required  className="form-control shadow-none" placeholder=" Title Here ..."/>
                                    </div>
                                    <div className="col-md-12 form-group">
                                        <label htmlFor="exampleFormControlTextarea1">Description</label>
                                        <textarea onChange={this.des} value={this.state.des} rows="4" cols="100" style={{width:"100%"}}>

                                         </textarea>
                                    </div>

                                    <div className="col-md-12">
                                        <label>NFT Created By</label>
                                        <input type="text" onChange={this.nftby} value={this.state.nftby} required  className="form-control shadow-none" placeholder=" NFT by ..."/>
                                    </div>
                                    <div className="col-md-12">
                                        <label>Price</label>
                                        <input type="text" onChange={this.price} value={this.state.price} required  className="form-control shadow-none" placeholder=" Price Here ..."/>
                                    </div>
                                    <div className="col-md-12">
                                        <label>Items</label>
                                        <input type="text" onChange={this.items} value={this.state.items} required  className="form-control shadow-none" placeholder=" Items Here ..."/>
                                    </div>
                                    <div className="col-md-12">
                                        <label>Volume</label>
                                        <input type="text" onChange={this.volume} value={this.state.volume} required  className="form-control shadow-none" placeholder=" Volume Here ..."/>
                                    </div>
                                    <div className="col-md-12">
                                        <label>Sales</label>
                                        <input type="text" onChange={this.sales} value={this.state.sales} required  className="form-control shadow-none" placeholder=" Sales Here ..."/>
                                    </div>
                                    <div className="col-md-12">
                                        <label>NFT Market Currency</label>
                                        <select id="inputState" required onChange={this.coin_id} className="form-control shadow-none">
                                            <option value="">Select  Coin</option>
                                            {coin}
                                        </select>
                                    </div>
                                    <div className="col-md-12">
                                        <label>NFT Status</label>
                                        <select id="inputState" required onChange={this.status} className="form-control shadow-none">
                                            <option slected={this.state.status=="Active"} value="Active">Active</option>
                                            <option slected={this.state.status=="Upcoming"} value="Upcoming">Upcoming</option>
                                        </select>
                                    </div>
                                    <div className="col-md-12">
                                        <label>Trade and Claim Status</label>
                                        <select id="inputState" required onChange={this.trade} className="form-control shadow-none">
                                            <option slected={this.state.trade=="Active"} value="Active">Active</option>
                                            <option slected={this.state.trade=="Inactive"} value="Inactive">Inactive</option>
                                        </select>
                                    </div>
                                    <div className="col-md-12">
                                        <label>Upcoming Date</label>
                                        <DatePicker selected={this.state.uptime!=""?new Date(this.state.uptime):new Date()} onChange={this.toDate} name="startDate"/>
                                    </div>
                                    <div className="col-md-12 mt-4 mb-5">
                                        <button type="button" onClick={this.onsubmitForm} className="btn btn-primary shadow-none">Update</button>
                                    </div>
                                </div>
                            </form>

                            <div className="col-md-12">
                                <label>Logo</label>
                                <input type="file" onChange={this.logo} className="form-control shadow-none" />
                                {this.state.logo2?<img id="showImage" className="mt-3 mb-3" style={{height:'50px',width:'auto'}} src={this.state.logo2} />:
                                    this.state.logo!==""?<img id="showImage" className="mt-3 mb-3" style={{height:'50px',width:'auto'}} src={ApiUrl.photoUrlNft+this.state.logo} />:""
                                }
                            </div>
                            <div className="col-md-12 mt-4 mb-5">
                                <button type="button" onClick={this.onsubmitFormLogo} className="btn btn-primary shadow-none"> Update</button>
                            </div>

                            <div className="col-md-12">
                                <label>Cover</label>
                                <input type="file" onChange={this.cover} className="form-control shadow-none" />
                                {this.state.cover2?<img id="showImage" className="mt-3 mb-3" style={{height:'50px',width:'auto'}} src={this.state.cover2} />:
                                    this.state.cover!==""?<img id="showImage" className="mt-3 mb-3" style={{height:'50px',width:'auto'}} src={ApiUrl.photoUrlNft+this.state.cover} />:""
                                }
                            </div>
                            <div className="col-md-12 mt-4 mb-5">
                                <button type="button" onClick={this.onsubmitFormCover} className="btn btn-primary shadow-none"> Update</button>
                            </div>
                            <br/><br/>
                        </div>
                    </div>
                </div>
            </Menu>
        );
    }
}

export default NftEdit;