import React, {Component} from 'react';
import Axios from "axios";
import AppUrl from "../AppUrl/ApiUrl";
import {toast} from "react-toastify";
import Menu from "../components/Menu";
import ApiUrl from "../AppUrl/ApiUrl";
import NotImg from "../images/404.png"
import Loading from "../components/Loading";

class UserUpdate extends Component {
    constructor({match}) {
        super();
        this.state={
            token:"",name:"",username:"",email:"",
            id:match.params.id,profile_status:"",usertype:"",
            blockstatusdata:[{name:"Account Active",val:"1"},{name:"Account Blocked",val:"0"}],
            role:[{name:"Admin"},{name:"Manager"},{name:"User"}],
            password:"",password_retype:"",loading:true,block_status:""
        }
    }
    componentDidMount() {
        var token = localStorage.getItem("admintoken")
        this.setState({token:token})
        const form = new FormData()
        form.append("token",token)
        form.append("id",this.state.id)
        Axios.post(AppUrl.baseurl+"oneuser",form)
            .then(res=>{
                console.log(res.data)
                if(res.data.error){
                    var a=0
                }else{
                    var val = res.data[0]
                    this.setState(
                        {name:val["name"],username:val["username"],email:val["email"],
                            status:val["status"],profile_status:val["profile_status"],
                            usertype:val["usertype"],loading:false,
                        }
                    )
                    //console.log(val["block_status"])
                }

            })
            .catch(err=>{
                console.log(err)
            })
    }

    name=(e)=>{
        this.setState({name:e.target.value})
    }
    username=(e)=>{
        this.setState({username:e.target.value})
    }
    email=(e)=>{
        this.setState({email:e.target.value})
    }
    status=(e)=>{
        this.setState({status:e.target.value})
    }
    profile_status=(e)=>{
        this.setState({profile_status:e.target.value})
    }
    usertype=(e)=>{
        this.setState({usertype:e.target.value})
    }
    password_retype=(e)=>{
        this.setState({password_retype:e.target.value})
    }
    password=(e)=>{
        this.setState({password:e.target.value})
    }
    onPasswordForm=(event)=>{
        var formData = new FormData()
        formData.append("token",this.state.token)
        formData.append("password",this.state.password)
        formData.append("password_retype",this.state.password_retype)
        formData.append("id",this.state.id)

        Axios.post(AppUrl.baseurl+"changepassadmin",formData)
            .then(res=> {
                if(res.data.success){
                    toast.success(res.data.success, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setTimeout(
                        () => this.props.history.push(`/`),
                        1000
                    )
                }else{
                    toast.error(res.data.error, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch(err=>{
                console.log(err)
            })

        event.preventDefault()
    }

    onsubmitForm=(event)=>{
        var formData = new FormData()
        formData.append("token",this.state.token)
        formData.append("name",this.state.name)
        formData.append("username",this.state.username)
        formData.append("profile_status",this.state.profile_status)
        formData.append("usertype",this.state.usertype)
        formData.append("block_status",this.state.block_status)
        formData.append("id",this.state.id)
        Axios.post(AppUrl.baseurl+"updateuseradmin",formData)
            .then(res=> {
                if(res.data.success){
                    toast.success(res.data.success, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setTimeout(
                        () => this.props.history.push(`/`),
                        1000
                    )
                }else{
                    toast.error(res.data.error, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch(err=>{
                console.log(err)
            })

        event.preventDefault()
    }

    render() {

        const blockst = this.state.blockstatusdata.map(res=>{
            return(<>
                    {this.state.profile_status==res.val?
                        <option selected value={res.val}>{res.name}</option>:
                        <option value={res.val}>{res.name}</option>
                    }
                </>
            )
        })

        const utype = this.state.role.map(res=>{
            return(<>
                    {this.state.usertype==res.name?
                        <option selected value={res.name}>{res.name}</option>:
                        <option value={res.name}>{res.name}</option>
                    }
                </>
            )
        })

        return (
            <Menu title={"Edit User "+this.state.username}>
                <h3 className="bg-white title-head"> Edit User {this.state.name} </h3>
                <div className="container-fluid currency-add">
                    <div className="row">

                        {this.state.loading==true?
                        <Loading/>:
                            <div className="col-md-11 bg-white m-3">
                                <form className="mt-5" onSubmit={this.onsubmitForm}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label>Full Name</label>
                                            <input type="text" onChange={this.name} value={this.state.name}  className="form-control shadow-none" />
                                        </div>
                                        <div className="col-md-6">
                                            <label>Userame</label>
                                            <input type="text" onChange={this.username} value={this.state.username} required  className="form-control shadow-none" />
                                        </div>
                                        <div className="col-md-6">
                                            <label>Email</label>
                                            <input type="text" disabled onChange={this.email} value={this.state.email} required  className="form-control shadow-none" />
                                        </div>
                                        <div className="col-md-6">
                                            <label>UID</label>
                                            <input disabled={true} type="text"  value={this.state.id}  className="form-control shadow-none" />
                                        </div>
                                        <div className="col-md-6">
                                            <label>Account Status</label>
                                            <select onChange={this.profile_status} className="form-select shadow-none" aria-label="Default select example">
                                                {blockst}
                                            </select>
                                        </div>
                                        <div className="col-md-6">
                                            <label>User Type </label>
                                            <select onChange={this.usertype} className="form-select shadow-none" aria-label="Default select example">
                                                {utype}
                                            </select>
                                        </div>
                                        <div className="col-md-12 mt-4 mb-5">
                                            <button type="submit" style={{width:"200px"}} className="btn btn-primary shadow-none">Update Profile</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        }

                        <div className="col-md-11 bg-white m-3 mb-3">
                            <form className="mt-5 " onSubmit={this.onPasswordForm}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <label>Password</label>
                                        <input type="password" onChange={this.password} value={this.state.password}  className="form-control shadow-none" />
                                    </div>
                                    <div className="col-md-6">
                                        <label>Retype Password</label>
                                        <input type="password" onChange={this.password_retype} value={this.state.password_retype} required  className="form-control shadow-none" />
                                    </div>
                                    <div className="col-md-12 mt-4 mb-5">
                                        <button type="submit" style={{width:"200px"}} className="btn btn-primary shadow-none">Update Password</button>
                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </Menu>
        );
    }
}

export default UserUpdate;