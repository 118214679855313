import React, {Component} from 'react';
import Axios from "axios";
import ApiUrl from "../AppUrl/ApiUrl";
import AppUrl from "../AppUrl/ApiUrl";
import {toast} from "react-toastify";
import ImgNot from "../images/404.png";
import moment from "moment";
import {Link} from "react-router-dom";
import Menu from "../components/Menu";
import Loading from "../components/Loading";

class Kyc extends Component {
    constructor() {
        super();
        this.state={
            data:[],name:"",user_count:"",
            token:"",loading:true,count:10,loading1:false
        }
    }

    componentDidMount() {
        this.users()
    }

    users=()=>{
        var token = localStorage.getItem("admintoken")
        this.setState({token:token})
        const formd = new FormData()
        formd.append("token",token)
        formd.append("limit",this.state.count)
        Axios.post(ApiUrl.baseurl+"alluserpending",formd)
            .then(res=>{
                console.log(res.data)
                if(res.data.error){
                    //this.users()
                }else{
                    this.setState({data:res.data.data,user_count:res.data.count,loading:false})
                }
            })
            .catch(err=>{
                console.log(err)
            })
    }

    onDel=(id,status)=>{
        var confirm = window.confirm("Are you sure to  "+status)
        const FormDa = new FormData()
        FormDa.append('token',this.state.token)
        FormDa.append('id',id)
        FormDa.append('status',status)
        if(confirm){
            this.setState({loading1:true})
            Axios.post(AppUrl.baseurl+"auth/kycaction",FormDa)
                .then(res=>{
                    if(res.data.success){
                        this.componentDidMount()
                        toast.success(res.data.success, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                     this.users()
                    }else{
                        toast.error(res.data.error, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });

                    }
                    this.setState({loading1:false})
                })
                .catch(error=>{
                    console.log(error)
                    this.setState({loading1:false})
                })
        }
    }

    loadMore=async (e)=>{
        var countval = this.state.count+50
        await this.setState({count:countval})
        await this.users()
    }

    render() {
        var datas = this.state.data.filter((val)=>{
            if(this.state.name==""){
                return val;
            }else if(val.username.toLowerCase().includes(this.state.name.toLowerCase())
                || val.email.toLowerCase().includes(this.state.name.toLowerCase()) || val.ref_code.toLowerCase().includes(this.state.name.toLowerCase()) ){
                return val;
            }
        }).map(res=>{
            return(
                <tr>
                    <td><Link to={"/user/details/"+res.id}>{res.id}</Link></td>
                    <td><Link to={"/user/details/"+res.id}>{res.email}</Link></td>
                    <td>{res.country}</td>
                    <td>{res.dtype}</td>
                    <td>{res.nidno}</td>
                    <td>
                        <img style={{height:"150px"}} src={ApiUrl.photoUrl+res.frontp}/>
                    </td>
                    <td>
                        <img style={{height:"150px"}} src={ApiUrl.photoUrl+res.backp}/>
                    </td>
                    <td>{res.telegram}</td>
                    <td>{res.twitter}</td>
                    <td>
                        <ul className="list-inline m-0">
                            <li className="list-inline-item">
                                <button disabled={this.state.loading1} className="btn btn-success btn-sm shadow-none rounded-0"
                                        onClick={this.onDel.bind(this,res.id,"Approved")}
                                        type="button" data-toggle="tooltip" data-placement="top"
                                        title="Edit">
                                    {this.state.loading1==true?"Loading...":<i className="fa fa-check"></i>}
                                </button>
                            </li>
                            <li className="list-inline-item">
                                <button disabled={this.state.loading1} className="btn btn-danger btn-sm rounded-0 shadow-none" type="button"
                                        onClick={this.onDel.bind(this,res.id,"Rejected")}  data-toggle="tooltip" data-placement="top"
                                        title="Delete">
                                    {this.state.loading1==true?"Loading...":<i className="fa fa-window-close"></i>}
                                    </button>
                            </li>
                        </ul>
                    </td>
                </tr>
            )
        })
        return (
            <Menu title={"Inreview KYC Users"}>
                <h3 className="bg-white title-head">Inreview KYC  ({this.state.user_count})</h3>
                <div className="container-fluid currency">
                    <div className="row">
                        <div className="col-md-12 p-0 m-0">

                            <div className="list bg-white p-2">

                                <div className="table-responsive mt-3">
                                    <table className="table table-bordered bg-white">
                                        <thead>
                                        <tr>
                                            <th>UID</th>
                                            <th>Email</th>
                                            <th>Country</th>
                                            <th>DType</th>
                                            <th>NID NO</th>
                                            <th>Front Image</th>
                                            <th>Back Image</th>
                                            <th>Telegram</th>
                                            <th>Twitter</th>
                                            <th>Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.loading==true?<Loading/>:datas}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.data.length>=this.state.count?
                        <div className="row" style={{margin:"0"}}>
                            <div className="col-md-12 loadmore" >
                                <button onClick={this.loadMore} className="btn btn-secondary btn-sm mb-3 shadow-none"> Load more ...</button>
                            </div>
                        </div>:""
                }
            </Menu>
        );
    }
}

export default Kyc;
