import React, {Component} from 'react';
import Axios from "axios";
import AppUrl from "../../../AppUrl/ApiUrl";
import {toast} from "react-toastify";

class NftAdjust extends Component {
    constructor(props) {
        super(props);
        this.state={
            token:"",walletdata:[],loading:true, name:"",uid:this.props.uid,user_id:"",email:"",
            coin_raw_id:"",balance:"0",balanceamount:"0", loadsend:false,
        }
    }

    async componentDidMount() {
        var token =await localStorage.getItem("admintoken")
        await this.setState({token:token})
        await this.myWallet()
        await this.createwallet()
    }
    createwallet=async ()=>{
        await Axios.get(AppUrl.baseurl+"nft/wallet/create/"+this.state.uid)
            .then(res=>{
                console.log(res.data)
            })
            .catch(err=>{
                console.log(err)
            })
    }
    balanceamount=(e)=>{
        this.setState({balanceamount:e.target.value})
    }
    type=(e)=>{
        this.setState({type:e.target.value})
    }
    coin_raw_id= (e)=>{
        this.setState({coin_raw_id:e.target.value})
        if(e.target.value==""){
            this.setState({balance:"0"})
        }else{
            const coinbal = this.state.walletdata.filter((val)=>{
                if(val.nft_id==e.target.value){
                    return val;
                }
            })
            this.setState({balance:coinbal[0]["bal"], symbol:coinbal[0]["title"]
            })

        }

    }

    myWallet=async ()=>{
        var token = localStorage.getItem("admintoken")
        if(token){
            this.setState({token:token})
            const formData = new FormData()
            formData.append("token",token)
            formData.append("id",this.state.uid)
            await Axios.post(AppUrl.baseurl+"nft/wallet/"+this.state.uid,formData)
                .then(res=>{
                    console.log(res.data)
                    if(res.data.error){
                        console.log(res.data.error)
                    }else{
                        this.setState({walletdata:res.data,loading:false})
                    }
                })
                .catch(err=>{
                    console.log(err)
                })
        }
    }


    errorMsg=(val)=>{
        toast.error(val, {
            theme: "colored",
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    successMsg=(val)=>{
        toast.info(val,  {
            theme: "colored",
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    formSubmit=async ()=>{
        console.log("Token ", this.state.token)
        console.log("nft_id " ,this.state.coin_raw_id)
        console.log("UID ",this.state.uid)
        console.log("Amount ",this.state.balanceamount)
        console.log("Type ",this.state.type)
        if(this.state.coin_raw_id==""){
            this.errorMsg("First Select nft")
        }else if(this.state.balanceamount==""){
            this.errorMsg("Balance Amount  atleast 0 required")
        }else if(this.state.type==""){
            this.errorMsg("Type is required")
        }else{
            this.setState({loading:true})
            var fda = new FormData()
            fda.append("token",this.state.token)
            fda.append("id",this.state.uid)
            fda.append("nft_id",this.state.coin_raw_id)
            fda.append("amount",this.state.balanceamount)
            fda.append("type",this.state.type)
            await Axios.post(AppUrl.baseurl+"nft/admin/balanceadjust",fda)
                .then(res => {
                    if (res.data.error) {
                        this.errorMsg(res.data.error)
                    } else {
                        this.successMsg(res.data.success)
                    }
                    this.setState({loading:false})
                })
                .catch(err => {
                    console.log(err)
                    this.setState({loading:false})
                })
        }
    }


    render() {
        const wallet = this.state.walletdata.map(res=>{
            return(
                <>
                    <option value={res.nft_id}>{res.title}</option>
                </>
            )

        });


        return (
            <>
                <div className="wallet">
                    <div className="container-fluid ">
                        <div className="row">

                            <div className="col-md-12 mt-5">
                                <div className="wallet-list table-responsive">
                                    <form >
                                        <p>
                                            Current Balance : {parseFloat(this.state.balance).toFixed(8)}<br/>
                                            <br/>
                                        </p>
                                        <div className="form-group mt-2">
                                            <label htmlFor="exampleInputEmail1">Wallet NFT </label>
                                            <select onChange={this.coin_raw_id} className="form-control shadow-none">
                                                <option selected value="">Select NFT</option>
                                                {wallet}
                                            </select>
                                        </div>
                                        <div className="form-group mt-2">
                                            <label htmlFor="exampleInputEmail1">Balance Update Type </label>
                                            <select onChange={this.type} className="form-control shadow-none">
                                                <option selected value="">Select Type</option>
                                                <option  value="Increment">Increment</option>
                                                <option  value="Decrement">Decrement</option>
                                            </select>
                                        </div>
                                        <div className="form-group mt-2">
                                            <label htmlFor="exampleInputEmail1">Amount QTN </label>
                                            <input onChange={this.balanceamount} value={this.state.balanceamount}
                                                   type="number" required className="form-control shadow-none" id="exampleInputEmail1"
                                                   aria-describedby="emailHelp" placeholder="0.001"/>
                                        </div>
                                        <button type="button" disabled={this.state.loading} onClick={this.formSubmit}
                                                className="btn btn-primary shadow-none mt-3">{this.state.loading==true?"Loading...":"Submit"}</button>
                                    </form>
                                    <br/>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default NftAdjust;