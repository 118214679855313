import React, {Component} from 'react';
import Menu from "../components/Menu";
import Axios from "axios";
import ApiUrl from "../AppUrl/ApiUrl";
import {toast} from "react-toastify";
import slugify from "slugify";
import AppUrl from "../AppUrl/ApiUrl";

class AddICO extends Component {
    constructor() {
        super();
        this.state={
            base:"",target:"",price:"",min_buy:"",
            token:"",coin:[]
        }
    }

    componentDidMount() {
        var token = localStorage.getItem("admintoken")
        this.setState({token:token})
        Axios.get(AppUrl.baseurl+"all-coin")
            .then(res=>{
                console.log(res.data)
                this.setState({coin:res.data})
            })
            .catch(err=>{
                console.log(err)
            })
    }
    base=(e)=>{
        this.setState({base:e.target.value})
    }
    target=(e)=>{
        this.setState({target:e.target.value})
    }
    price=(e)=>{
        this.setState({price:e.target.value})
    }
    min_buy=(e)=>{
        this.setState({min_buy:e.target.value})
    }

    onSubmitForm= (event)=>{
        var val = this.state
        var formData = new FormData()
        formData.append("token",val.token)
        formData.append("base",val.base)
        formData.append("target",val.target)
        formData.append("price",val.price)
        formData.append("min_buy",val.min_buy)
        Axios.post(ApiUrl.baseurl+"add-ico", formData)
            .then(res=>{
                if(res.data.success){
                    toast.success(res.data.success, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setTimeout(
                        () => this.props.history.push(`/ico`),
                        300
                    )
                }else{
                    toast.error(res.data.error, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch(error=>{
                console.log(error)
            })

        event.preventDefault()
    }

    render() {
        var base = this.state.coin.map(res=>{
            return(
                <>
                    <option selected={res.id == this.state.base} value={res.id}>{res.coin_symbol}</option>
                </>
            )

        })
        var target = this.state.coin.map(res=>{
            return(
                <>
                    <option selected={res.id == this.state.target} value={res.id}>{res.coin_symbol}</option>
                </>
            )

        })
        return (
            <Menu title="Add ICO Market">
                <h3 className="bg-white title-head"> Add ICO Market </h3>
                <div className="container-fluid currency-add">
                    <div className="row">
                        <div className="col-md-12">
                            <br/><br/>
                            <form onSubmit={this.onSubmitForm}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <label>Price</label>
                                        <input type="text" required value={this.state.price} onChange={this.price} className="form-control shadow-none" placeholder="0.0 "/>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Base Coin</label>
                                        <select id="inputState" required onChange={this.base} className="form-control shadow-none">
                                            <option value="">Select Base Coin</option>
                                            {base}
                                        </select>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Minimum Buy</label>
                                        <input type="text" value={this.state.min_buy} onChange={this.min_buy} className="form-control shadow-none" required placeholder="0 "/>
                                    </div>

                                    <div className="col-md-6">
                                        <label>Market Coin</label>
                                        <select id="inputState" required onChange={this.target} className="form-control shadow-none">
                                            <option value="">Select Market Coin </option>
                                            {target}
                                        </select>
                                    </div>
                                    <div className="col-md-12 mt-4 mb-5">
                                        <button type="submit" className="btn btn-primary shadow-none">Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Menu>
        );
    }
}

export default AddICO;