import React, {Component} from 'react';
import Axios from "axios";
import AppUrl from "../../../AppUrl/ApiUrl";
import notimg from "../../../images/404.png";
import Loading from "../../Loading";

class NftWallet extends Component {
    constructor(props) {
        super(props);
        this.state={
            token:"",walletdata:[],loading:true, name:"",uid:this.props.uid,user_id:"",
        }
    }

    async componentDidMount() {
        await this.setState({uid:this.props.uid,user_id:this.props.user_id})
        await this.createwallet()
        await this.myWallet()
    }

    createwallet=async ()=>{
        await Axios.get(AppUrl.baseurl+"nft/wallet/create/"+this.state.uid)
            .then(res=>{
                console.log(res.data)
            })
            .catch(err=>{
                console.log(err)
            })
    }
    myWallet=async ()=>{
        var token = localStorage.getItem("admintoken")
        if(token){
            this.setState({token:token})
            const formData = new FormData()
            formData.append("token",token)
            formData.append("id",this.state.uid)
            await Axios.post(AppUrl.baseurl+"nft/wallet/"+this.state.uid,formData)
                .then(res=>{
                    if(res.data.error){
                        console.log(res.data.error)
                    }else{
                        this.setState({walletdata:res.data,loading:false})
                        // console.log(res.data.data)
                    }
                })
                .catch(err=>{
                    console.log(err)
                })
        }
    }

    onNameFilter=(e)=>{
        this.setState({name:e.target.value})
    }


    render() {
        const wallet = this.state.walletdata.map( res=>{
            let address=""
            let bal="0"

            return(
                <>
                    <tr>
                        <td>
                            {res.logo?
                                <img src={AppUrl.photoUrlNft+res.logo} />:
                                <img src={notimg} />
                            }
                            {res.title}
                        </td>
                        <td>{res.title}</td>
                        <td>{parseFloat(res.bal)} QTN</td>
                    </tr>
                </>
            )

        });


        return (
            <>
                <div className="wallet">
                    <div className="container-fluid ">
                        <div className="row">

                            <div className="col-md-12 mt-2">
                                <div className="wallet-list table-responsive">

                                    <table className="table  table-bordered">
                                        <thead>
                                        <tr>
                                            <th>NFT Logo</th>
                                            <th>NFT Name</th>
                                            <th>NFT Balance</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            this.state.loading==true?
                                                <Loading/>:
                                                wallet
                                        }

                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default NftWallet;