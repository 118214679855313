import React, {Component} from 'react';
import Axios from "axios";
import ApiUrl from "../AppUrl/ApiUrl";
import AppUrl from "../AppUrl/ApiUrl";
import {toast} from "react-toastify";
import ImgNot from "../images/404.png";
import {Link} from "react-router-dom";
import Menu from "../components/Menu";
import Loading from "../components/Loading";

class Nft extends Component {

    constructor() {
        super();
        this.state={
            data:[],name:"",count:20,
            token:"",loading:true
        }
    }

    componentDidMount() {
        var token = localStorage.getItem("admintoken")
        this.setState({token:token})
        var formd = new FormData()
        formd.append("token",token)
        Axios.get(ApiUrl.baseurl+"nft/all",formd)
            .then(res=>{
                if(res.data.error){
                    var a=0
                }else{
                    this.setState({data:res.data,loading:false})
                }
                console.log(res.data)
            })
            .catch(err=>{
                console.log(err)
            })
    }

    onName=(e)=>{
        this.setState({name:e.target.value})
    }
    onDel=(id)=>{
        var confirm = window.confirm("Are you sure to delete ")
        const FormDa = new FormData()
        FormDa.append('token',this.state.token)
        FormDa.append('id',id)
        console.log(id)
        if(confirm){
            Axios.post(AppUrl.baseurl+"nft/delete",FormDa)
                .then(res=>{
                    if(res.data.success){
                        this.componentDidMount()
                        toast.success(res.data.success, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });

                    }else{
                        toast.error(res.data.error, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });

                    }
                })
                .catch(error=>{
                    console.log(error)
                })
        }
    }

    render() {
        var countval=0;
        var datas = this.state.data.filter((val)=>{
            if(this.state.name==""){
                return val;
            }else if(val.title.toLowerCase().includes(this.state.name.toLowerCase()) || val.des.toLowerCase().includes(this.state.name.toLowerCase())){
                return val;
            }
        }).map(res=>{
            countval+=1
            if(countval<=this.state.count){
                return(
                    <tr>
                        <td>{res.id}</td>
                        <td>{res.title}</td>
                        <td>{res.des.substring(0,30)}...</td>
                        <td>
                            {res.logo?
                                <img style={{height:"30px",width:"30px"}} src={AppUrl.photoUrlNft+res.logo}/>
                                :
                                <img style={{height:"30px",width:"30px"}} src={ImgNot}/>
                            }
                        </td>
                        <td>
                            {res.cover?
                                <img style={{height:"30px",width:"30px"}} src={AppUrl.photoUrlNft+res.cover}/>
                                :
                                <img style={{height:"30px",width:"30px"}} src={ImgNot}/>
                            }
                        </td>
                        <td>{res.price} {res.coin_symbol}</td>
                        <td>{res.items}</td>
                        <td>{res.count}</td>
                        <td>{res.volume} </td>
                        <td>{res.sales}</td>
                        <td>{res.coin_symbol}</td>
                        <td>{res.status}</td>
                        <td>{res.trade}</td>
                        <td>{res.updated_at}</td>
                        <td>
                            <ul className="list-inline m-0">
                                <li className="list-inline-item">
                                    <Link to={"/nft/edit/"+res.id}className="btn btn-success btn-sm shadow-none rounded-0"
                                          type="button" data-toggle="tooltip" data-placement="top"
                                          title="Edit"><i className="fa fa-edit"></i></Link>
                                </li>
                                <li className="list-inline-item">
                                    <button className="btn btn-danger btn-sm rounded-0 shadow-none" type="button"
                                            onClick={this.onDel.bind(this,res.id)} data-toggle="tooltip" data-placement="top"
                                            title="Delete"><i className="fa fa-trash"></i></button>
                                </li>
                            </ul>
                        </td>
                    </tr>
                )
            }

        })

        return (
            <Menu title="All NFT">
                <h3 className="bg-white title-head"> NFT ({this.state.data.length})</h3>
                <div className="container-fluid currency">
                    <div className="row">
                        <div className="col-md-12 p-0 m-0">

                            <div className="list bg-white p-2">
                                <div className="taddb">
                                    <Link to="/nft/add" className="btn btn-primary btn-sm pl-5 shadow-none">Add NFT </Link>
                                    <input onChange={this.onName} placeholder="Search Title Description"
                                           className="form-control shadow-none form-control-sm"/>
                                </div>
                                <div className="table-responsive mt-3">
                                    <table className="table table-bordered bg-white">
                                        <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Title</th>
                                            <th>Description</th>
                                            <th>Logo</th>
                                            <th>Cover</th>
                                            <th>Price</th>
                                            <th>Items</th>
                                            <th>Claimed</th>
                                            <th>Volume</th>
                                            <th>Sales</th>
                                            <th>Coin</th>
                                            <th>Status</th>
                                            <th>Trade</th>
                                            <th>Date of update (UTC)</th>
                                            <th>Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.loading==true?<Loading/>:datas}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Menu>
        );
    }
}

export default Nft;