import React, {Component} from 'react';
import Menu from "../components/Menu";
import Axios from "axios";
import AppUrl from "../AppUrl/ApiUrl";
import {toast} from "react-toastify";
import Loading from "../components/Loading";
import ReactQuill from "react-quill";

const modules = {
    toolbar: [
        [{size: []}],
        [{ 'font': [] }],
        ['bold', 'italic', ],//'underline', 'strike', 'blockquote'
        //[{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        ['link'],
        ['clean']
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: true,
    }
}

class ReferralSettings extends Component {
    constructor() {
        super();
        this.state={
            data:[],token:"",registerbonus:"",referralbonus:"",registerstatus:"",
            coin_raw_id:"",miningbonus:"",miningstatus:"",mining_fee:"",fee_coin:"",
            withdrew_status:"",min_transfer:"",mining_amount:"",
            reg_bonus:"",buy_bonus:"",sell_bonus:"",airdroptext:"",
            status:"true",bonus_count:"",transfer:"",loading:true,withdraw:"",
            rverify:"",lverify:"",wverify:"",refstatus:"",
            cond:[{name:"true",val:"True"},{name:"false",val:"False"}],
            wcond:[{name:"Manual"},{name:"Auto"}]
        }

    }
    async componentDidMount() {
        var token = localStorage.getItem("admintoken")
        this.setState({token:token})
        await Axios.get(AppUrl.baseurl+"all-coin")
            .then(res=>{
                console.log(res)
                if (res.data.error) {
                    this.setState({loading: false})
                } else {
                    this.setState({data: res.data, loading: false})
                }
            })
            .catch(err=>{
                console.log(err)
            })
        await Axios.get(AppUrl.baseurl+"settings/get")
            .then(res=>{
                if (res.data.error) {
                    this.setState({loading: false})
                } else {
                    var val = res.data
                    //console.log(val)
                    this.setState({registerbonus:val["registerbonus"],referralbonus:val["referralbonus"],
                        registerstatus:val["registerstatus"], coin_raw_id:val["coin_raw_id"],miningbonus:val["miningbonus"],
                        miningstatus:val["miningstatus"],mining_fee:val["mining_fee"],fee_coin:val["fee_coin"],
                        withdrew_status:val["withdrew_status"],min_transfer:val["min_transfer"],refstatus:val["refstatus"],
                        mining_amount:val["mining_amount"],airdroptext:val["airdroptext"],loading:false
                    })
                }

            })
            .catch(err=>{
                console.log(err)
            })
    }

    refstatus=(e)=>{
        this.setState({refstatus:e.target.value})
    }
    registerbonus=(e)=>{
        this.setState({registerbonus:e.target.value})
    }
    referralbonus=(e)=>{
        this.setState({referralbonus:e.target.value})
    }
    registerstatus=(e)=>{
        this.setState({registerstatus:e.target.value})
    }
    miningbonus=(e)=>{
        this.setState({miningbonus:e.target.value})
    }
    miningstatus=(e)=>{
        this.setState({miningstatus:e.target.value})
    }
    mining_fee=(e)=>{
        this.setState({mining_fee:e.target.value})
    }
    fee_coin=(e)=>{
        this.setState({fee_coin:e.target.value})
    }
    withdrew_status=(e)=>{
        this.setState({withdrew_status:e.target.value})
    }
    min_transfer=(e)=>{
        this.setState({min_transfer:e.target.value})
    }
    mining_amount=(e)=>{
        this.setState({mining_amount:e.target.value})
    }

    coin_raw_id=(e)=>{
        this.setState({coin_raw_id:e.target.value})
    }

    airdroptext=(content, delta, source, editor)=>{
        this.setState({airdroptext:content})
    }
    onsubmitForm=async (event)=>{
        var formData = new FormData()
        formData.append("token",this.state.token)
        formData.append("coin_raw_id",this.state.coin_raw_id)
        formData.append("registerbonus",this.state.registerbonus)
        formData.append("referralbonus",this.state.referralbonus)
        formData.append("registerstatus",this.state.registerstatus)
        formData.append("miningbonus",this.state.miningbonus)
        formData.append("miningstatus",this.state.miningstatus)
        formData.append("mining_fee",this.state.mining_fee)
        formData.append("fee_coin",this.state.fee_coin)
        formData.append("withdrew_status",this.state.withdrew_status)
        formData.append("min_transfer",this.state.min_transfer)
        formData.append("mining_amount",this.state.mining_amount)
        formData.append("airdroptext",this.state.airdroptext)
        formData.append("refstatus",this.state.refstatus)
        /*await Axios.post(AppUrl.baseurl+"settings",formData)
            .then(res=> {
                if(res.data.success){
                    toast.success(res.data.success, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }else{
                    toast.error(res.data.error, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch(err=>{
                console.log(err)
            })*/


        //event.preventDefault()
    }

    render() {
        var basecurrency = this.state.data.map(res=>{
            return(
                <>
                    <option selected={this.state.coin_raw_id==res.id} value={res.id}>{res.coin_symbol}</option>
                </>
            )

        })

        var feecoin = this.state.data.map(res=>{
            return(
                <>
                    <option selected={this.state.fee_coin==res.id} value={res.id}>{res.coin_symbol}</option>
                </>
            )

        })

        return (
            <Menu title="Referral and Mining Settings">
                <h3 className="bg-white title-head">  Settings  </h3>
                <div className="container-fluid currency-add">
                    <div className="row">
                        <div className="col-md-11">
                            {
                                this.state.loading==true?<Loading/>:
                                    <form className="mt-5">
                                        <div className="row">

                                            <div className="col-md-6">
                                                <label>Your Coin</label>
                                                <select onChange={this.coin_raw_id}  id="inputState" required  className="form-control shadow-none">
                                                    <option>Select Coin</option>
                                                    {basecurrency}
                                                </select>
                                            </div>


                                            <div className="col-md-6">
                                                <label>Registration Bonus</label>
                                                <input type="number" onChange={this.registerbonus} required value={this.state.registerbonus} className="form-control shadow-none" placeholder=""/>
                                            </div>
                                            <div className="col-md-6">
                                                <label>Referral Bonus</label>
                                                <input type="number" onChange={this.referralbonus} required value={this.state.referralbonus} className="form-control shadow-none"
                                                       placeholder=""/>
                                            </div>
                                            <div className="col-md-6">
                                                <label>Register Bonus Status </label>
                                                <select onChange={this.registerstatus}  id="inputState" required  className="form-control shadow-none">
                                                    <option selected={this.state.registerstatus=="Yes"} value="Yes">Yes</option>
                                                    <option selected={this.state.registerstatus=="No"} value="No">No</option>
                                                </select>
                                            </div>
                                            {/*<div className="col-md-6">
                                                <label>Mining Bonus</label>
                                                <input type="number" onChange={this.miningbonus} required value={this.state.miningbonus} className="form-control shadow-none"
                                                       placeholder=""/>
                                            </div>*/}
                                            <div className="col-md-6">
                                                <label>Mining Activity </label>
                                                <select onChange={this.miningstatus}  id="inputState" required  className="form-control shadow-none">
                                                    <option selected={this.state.miningstatus=="Yes"} value="Yes">Yes</option>
                                                    <option selected={this.state.miningstatus=="No"} value="No">No</option>
                                                </select>
                                            </div>
                                            <div className="col-md-6">
                                                <label>Mining Fee</label>
                                                <input type="number" onChange={this.mining_fee} required value={this.state.mining_fee} className="form-control shadow-none"
                                                       placeholder=""/>
                                            </div>

                                            <div className="col-md-6">
                                                <label>Transfer Fee Coin</label>
                                                <select onChange={this.fee_coin}  id="inputState" required  className="form-control shadow-none">
                                                    <option value="">Select Coin</option>
                                                    {feecoin}
                                                </select>
                                            </div>
                                            <div className="col-md-6">
                                                <label>Withdrew System </label>
                                                <select onChange={this.withdrew_status}  id="inputState" required  className="form-control shadow-none">
                                                    <option selected={this.state.withdrew_status=="Manual"} value="Manual">Manual</option>
                                                    <option selected={this.state.withdrew_status=="Auto"} value="Auto">Auto</option>
                                                </select>
                                            </div>
                                            <div className="col-md-6">
                                                <label>Mining Minimum Transfer Amount</label>
                                                <input type="number" onChange={this.min_transfer} required value={this.state.min_transfer} className="form-control shadow-none"
                                                       placeholder=""/>
                                            </div>
                                            <div className="col-md-6">
                                                <label>Mining Bonus Amount</label>
                                                <input type="number" onChange={this.mining_amount} required value={this.state.mining_amount} className="form-control shadow-none"
                                                       placeholder=""/>
                                            </div>
                                            <div className="col-md-6">
                                                <label>Referral Status</label>

                                                <select onChange={this.refstatus}  id="inputState" required  className="form-control shadow-none">
                                                    <option selected={this.state.refstatus=="Yes"} value="Yes">Yes</option>
                                                    <option selected={this.state.refstatus=="No"} value="No">No</option>
                                                </select>
                                            </div>
                                            <div className="col-md-12">
                                                <label>Airdrop Announcement</label>
                                                <ReactQuill style={{height:"150px"}}
                                                            modules={modules}
                                                            onChange={this.airdroptext} value={this.state.airdroptext} className="editor" theme="snow" />
                                                <br/><br/>
                                            </div>
                                            <div className="col-md-12 mt-4 mb-5">
                                                <button type="button" onClick={this.onsubmitForm} className="btn btn-primary shadow-none">Update</button>
                                            </div>
                                        </div>
                                    </form>
                            }
                        </div>
                    </div>
                </div>
            </Menu>
        );
    }
}

export default ReferralSettings;