import React, {Component} from 'react';
import {Link} from "react-router-dom";
import Payments from "./Payments";
import Wallets from "./Wallets";
import BalanceAdjustUser from "./BalanceAdjustUser";
import NftHistoryMy from "./Nft/NftHistoryMy";
import NftOrderMy from "./Nft/NftOrderMy";
import NftWallet from "./Nft/NftWallet";
import NftAdjust from "./Nft/NftAdjust";

class NftUser extends Component {
    constructor(props) {
        super(props);
        this.state={
            path:"nftpayments",id:this.props.uid

        }
    }
    onReportChange=(name)=>{
        this.setState({path:name})
    }
    render() {
        return (
            <div className="report ">
                <ul className="report-head">
                    <li onClick={this.onReportChange.bind(this,"nftpayments")}>
                        <Link to={"/user/details/"+this.state.id} className={this.state.path=="nftpayments"?"active":""}>NFT History</Link>
                    </li>
                    <li onClick={this.onReportChange.bind(this,"nftorder")}>
                        <Link to={"/user/details/"+this.state.id} className={this.state.path=="nftorder"?"active":""}>NFT Order</Link>
                    </li>
                    <li onClick={this.onReportChange.bind(this,"nftwallet")}>
                        <Link to={"/user/details/"+this.state.id} className={this.state.path=="nftwallet"?"active":""}>NFT Wallet</Link>
                    </li>
                    <li onClick={this.onReportChange.bind(this,"nftbalance")}>
                        <Link to={"/user/details/"+this.state.id} className={this.state.path=="nftbalance"?"active":""}>Nft Balance Adjust</Link>
                    </li>
                </ul>
                <div className="report-body bg-white">
                    {this.state.path=="nftpayments"?<NftHistoryMy uid={this.state.id}/>:""}
                    {this.state.path=="nftorder"?<NftOrderMy uid={this.state.id}/>:""}
                    {this.state.path=="nftwallet"?<NftWallet uid={this.state.id}/>:""}
                    {this.state.path=="nftbalance"?<NftAdjust uid={this.state.id}/>:""}
                </div>
                <br/><br/>
            </div>
        );
    }
}

export default NftUser;